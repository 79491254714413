@import "letrus-ui/dist/styles/_letrusTheme.scss";
@import "~bootstrap-scss/functions";
@import "~bootstrap-scss/variables";
@import "~bootstrap-scss/maps";
@import "~bootstrap-scss/mixins";
@import "~bootstrap-scss/grid";
@import "~bootstrap-scss/reboot";

* {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scale13;
  }
}

*:before,
*:after {
  box-sizing: inherit;
}

body.modal-open {
  overflow: initial !important;
}

.link:hover {
  cursor: pointer;
}

.site-container {
  font-size: 16px !important;
  border: 30px solid #ffffff;
  background: #fafafa;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;

  a {
    background: transparent;
    text-decoration: none;
    transition: color 0.2s;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  .wrapper {
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 1200px;
    -ms-flex-direction: column;
    flex-direction: column;

    .contact-data {
      p {
        text-align: center;
        margin: 0 auto;

        &.promo {
          font-weight: 700;
          margin-bottom: 10px;

          a:hover {
            border-bottom: 2px solid $vivid-blue;
          }
        }
      }
      margin-bottom: 60px;
    }
  }

  @media (max-width: 1500px) {
    .wrapper {
      width: 90%;
    }
  }

  @media (max-width: 700px) {
    .wrapper {
      width: 100%;
      margin: 0;
    }
  }

  .main-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .page-title {
    font-size: 24px;
    font-weight: bold;
    color: #406079;
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #406079;
  }

  .quote {
    font-style: italic;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-underlined {
    position: relative;
  }

  .text-underlined:before {
    display: block;
    content: "";
    position: absolute;
    width: 95%;
    height: 15px;
    bottom: 3px;
    background: rgba(64, 96, 121, 0.4);
    z-index: -1;
    left: 15px;
  }

  .home {
    background: rgba(41, 251, 199, 0.5);
    background: linear-gradient(
      45deg,
      rgba(41, 251, 199, 0.8) 0%,
      rgba(128, 196, 229, 0.8) 100%
    );
    position: absolute;
    z-index: 99;
  }

  .home .nav-item-link {
    color: #ffffff;
  }

  .home .main-container {
    -ms-flex-align: center;
    align-items: center;
  }

  .home .text-intro {
    color: #ffffff;
    font-weight: bold;
    text-align: left;
    font-size: 64px;
    margin: 0;
    width: 90%;
  }
  @media (max-width: 1024px) {
    .home .text-intro {
      font-size: 42px;
    }
  }
  @media (max-width: 700px) {
    .home .text-intro {
      font-size: 32px;
    }
  }

  .home .text-description {
    margin-bottom: 30px;
    line-height: 24px;
    color: #406079;
    width: 70%;
  }

  .home .btn-primary {
    margin-top: 20px;
  }

  .about .main-container {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about .text-container {
    width: 60%;
    margin-right: 50px;
    text-align: justify;
    line-height: 21px;
  }
  @media (max-width: 840px) {
    .about .text-container {
      width: 100%;
    }
  }

  .about .featured-text {
    font-size: 32px;
    font-weight: bold;
    padding: 0 80px;
    line-height: 42px;
    text-align: center;
    color: #58dcd1;
  }
  @media (max-width: 700px) {
    .about .featured-text {
      padding: 0;
    }
  }

  .about .image-container {
    width: 35%;
    height: 20vw;
    border: 5px solid #58dcd1;
    position: absolute;
    top: 30%;
    right: 0;
  }
  @media (max-width: 840px) {
    .about .image-container {
      width: 70%;
      position: relative;
      height: 30vw;
      margin: auto;
      margin-top: 25%;
    }
  }

  .about .image-container .image {
    width: 100%;
    transform: translate(30px, -20%);
  }
  @media (max-width: 840px) {
    .about .image-container .image {
      transform: translate(30px, -40%);
    }
  }

  .contact .main-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    .contact .main-container {
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .contact .label {
    margin-bottom: 10px;
  }

  .contact .contact-info {
    width: 40%;
  }
  @media (max-width: 1024px) {
    .contact .contact-info {
      width: 100%;
    }
  }

  .contact .contact-info p {
    line-height: 24px;
  }

  .contact .contact-info-section {
    margin: 40px 0;
  }

  .contact .form {
    width: 40%;
  }
  @media (max-width: 1024px) {
    .contact .form {
      width: 100%;
    }
  }

  .contact .form .btn-primary {
    float: right;
  }

  .login .main-container {
    text-align: center;
  }

  .login-container {
    background: #ffffff;
    padding: 50px;
    margin: 0 auto;
    border: 1px solid #e6e6e6;
    max-width: 550px;

    .help-block {
      font-size: 12px;
      font-weight: 700;
    }

    .error-message {
      background-color: $error;
      color: $white;
      font-weight: 700;
      padding: 10px;
      margin: 10px 0;
    }
  }

  .login-container .section-subtitle {
    font-size: 12px;
    text-transform: uppercase;
  }

  .login-container .actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    margin: 10px 0 20px;
  }

  @media (max-width: 700px) {
    .login-container {
      width: calc(100% - 60px);
      margin: 0 auto;
    }
  }

  @media (max-width: 700px) {
    .login-container .actions {
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  @media (max-width: 700px) {
    .login-container .actions .btn-primary {
      width: 100%;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }

  .login-container .actions .save-user {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-align: center;
    align-items: center;
  }

  .login-container .actions .save-user .label {
    font-size: 11px;
    line-height: 11px;
  }

  .login-container .actions .save-user .input {
    margin: 0 10px 0 20px;
    width: 10px;
  }

  .link-small {
    color: #406079;
    text-decoration: none;
    font-size: 14px;

    &.final-link {
      margin-top: 40px;
      display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .header {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 40px 0;
  }
  @media (max-width: 700px) {
    .header {
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .header .logo {
    width: 125px;
  }
  @media (max-width: 700px) {
    .header .logo {
      margin-bottom: 20px;
      width: 100px;
    }
  }

  .main-nav ul {
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 700px) {
    .main-nav ul {
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }

  .nav-item {
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    padding-left: 15px;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-item:not(:last-child):after {
    color: #ffffff;
    content: "\00b7";
    font-size: 21px;
    margin-left: 15px;
  }

  .nav-item:first-child {
    margin-left: 0;
  }

  .nav-item-link {
    color: #406079;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;

    &.active {
      color: $dark-grey;
    }
  }

  .nav-item-link:hover {
    font-weight: bold;
    text-decoration: none;
  }

  .footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
    color: #406079;
  }

  @media (max-width: 700px) {
    .footer {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 50px;

      .TermsPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;

        .TermsPage__LinksList {
          padding-left: 0;
        }
      }
    }
  }

  .footer .social-links {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }

  .footer {
    .TermsPage,
    .TermsPage__LinksList {
      display: inherit;

      .TermsPage__LinkItem {
        list-style: none;
      }

      .TermsPage__LinkItem:not(:first-child) {
        margin-left: 20px;
      }
    }

    .social-links-item {
      list-style: none;
    }

    .social-links-item:not(:first-child) {
      margin-left: 20px;
    }

    .social-links-link {
      color: #406079;
    }
  }

  .TermsContainer {
    display: block !important;
    color: #7a7a7a;

    .TermsTitle {
      font-weight: bold;
      color: #7a7a7a;
      text-transform: uppercase;
    }

    .TermsDate {
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .home .social-links-link {
    color: #ffffff;
  }

  .btn-primary {
    background: #406079;
    font-weight: 400;
    padding: 10px 20px;
    border: 0;
    border-radius: 20px;
    color: #ffffff;
    transition: all 0.25s ease;
    cursor: pointer;
    font-size: 16px !important;
    line-height: 20px;
  }

  .btn-primary:hover {
    background: #527a9a;
  }

  .social-login {
    float: right;
    text-align: right;
    top: -28px;
    position: relative;

    a {
      display: block;

      button {
        color: #406079;
        text-decoration: none;
        font-size: 14px;
        background: none;
        border: none;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .btn-social-login {
    border: 0;
    border-radius: 20px;
    color: #ffffff;
    padding: 7px 15px 7px 35px;
    margin: 20px 0;
    width: 220px;
    cursor: pointer;
    font-weight: 400;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 15px !important;
  }

  .btn-social-login i {
    margin-right: 10px;
    position: absolute;
    bottom: 5px;
    font-size: 28px;
    left: 15px;
  }

  .partiners {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 100px 0;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .partiners-title {
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #406079;
    margin-right: 30px;
    font-style: italic;
  }

  .partiners-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 700px) {
    .partiners-container {
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 30px 0;
    }
  }

  .partiner {
    list-style: none;
    margin-right: 30px;
    height: 40px;
    filter: grayscale(100%);
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.25s ease;
  }
  @media (max-width: 700px) {
    .partiner {
      margin: 20px 0;
    }
  }

  .partiner:hover {
    filter: grayscale(0);
    opacity: 1;
  }

  .partiner img {
    height: 100%;
    width: auto;
  }

  .input-box {
    display: block;
    margin: 10px 0;
    min-height: 80px;
    height: 80px;
  }

  .label {
    color: $dark-grey !important;
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
  }

  .input,
  .select,
  .textarea {
    border: 0;
    background: none;
    width: 100%;
    padding: 10px;
    color: $dark-grey;
  }

  .input {
    border-bottom: 1px solid $dark-grey;
  }

  .textarea {
    height: 150px;
    border: 1px solid $dark-grey;
  }

  .video-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }

  .signup-container {
    padding: 15px 50px;

    .input-box {
      margin: 10px 0 0 0;
    }

    .input {
      font-size: 15px;
      padding: 2px 10px;
    }

    .label {
      padding: 0 5px;
    }

    .help-block {
      margin: 5px, 0;
    }

    .DateTimeGroup {
      display: flex;
    }
  }
}

@media (max-width: 700px) {
  .site-container {
    border: 0;

    .social-login {
      float: none;
      text-align: left;
      top: 10px;
    }

    .link-small.final-link {
      margin-top: 20px;
    }
  }
}

#text_editor {
  p {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
  }
}

button.a-clone {
  background-color: none;
  border: none;
  padding: 0;
  margin: 0;
}

.Tour {
  svg {
    position: relative;
    top: -10px;
  }
}

.tippy-box[data-theme~="dark"] {
  background-color: $black;
  border-radius: 1rem;
  padding-inline: 1rem;
  color: $white;

  & > .tippy-arrow::before {
    border-color: red;
  }
}

.tippy-box[data-theme~="dark"] > .tippy-svg-arrow > svg:first-child {
  fill: $black;
  transform: rotate(180deg) translateY(0.8rem);
}
