@import "letrus-ui/dist/styles/_letrusTheme.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    gap: 0.5rem;
  }
}
