/**
 * Provides styles for Letrus tooltips and rc-tooltip
 */

@import "../../global-styles.scss";

.LightTooltipOverlay {
  animation: fade-in-bounce $animation-base-duration $bounce-over-out-easing;
  background: $reviewer-dark;
  box-shadow: 0 0 4px 4px rgba(black, 0.05);
  color: black;
  min-width: 300px;
  transition: all 0.4 ease-out;
  will-change: opacity transform;
  margin: -12px;
  padding: 10px;
}

.TooltipOverlay {
  border-radius: 6px;
  box-shadow: 0 0 4px 4px rgba(black, 0.05);
  background: $reviewer-dark;
  min-width: 300px;
  animation: fade-in-bounce $animation-base-duration $bounce-over-out-easing;
  transition: all 0.4 ease-out;
  will-change: opacity transform;

  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }

  .TooltipOverlay__title {
    background-color: $reviewer-dark;
    text-align: left;
    padding: 15px;
    font-weight: 600;
    font-size: 16px;
  }

  .TooltipOverlay__section {
    padding: 30px 15px 0 15px;

    &:nth-last-child(1) {
      padding-bottom: 30px;
    }
  }

  .TooltipOverlay__sectionTitle {
    font-weight: 600;
    font-size: 16px;
  }

  .TooltipOverlay__sectionBody {
    font-weight: 200;
    opacity: 0.9;

    ul {
      padding-left: 15px;
    }
  }

  .TooltipOverlay__body {
    max-height: 85vh;
    overflow-y: scroll;

    .rc-tooltip-inner {
      padding: 0 !important;
    }

    p {
      margin-bottom: 0;
      color: white;
    }

    .TooltipSection__NoCommentOrSuggestion {
      padding: 15px;
    }
  }

  &__action {
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 15px;
    margin-top: 10px;
    vertical-align: middle;

    span {
      display: block;
      margin-top: 5px;
    }

    button {
      background-color: transparent;
      border: 0;
    }

    .thumbs {
      font-size: 20px;
      margin-right: 10px;
      vertical-align: middle;
      cursor: pointer;
      color: $white;

      &.liked,
      &.disliked {
        &::before {
          transition: color 0.8s;
        }
      }

      &.liked {
        color: $success;
        animation: likeAnimation 0.8s;
      }

      &.disliked {
        color: $teacher-accent-secondary;
        animation: likeAnimation 0.8s;
      }
    }
  }
}

.rc-tooltip {
  z-index: 5 !important;
  opacity: 1;

  .rc-tooltip-inner {
    background: $reviewer-dark;
    color: white;
    font-weight: 200;
    font-size: 14px;
    padding: 10px;
    border: 0;
    max-width: 300px;
  }

  .rc-tooltip-arrow {
    display: none;
  }

  &.no-padding {
    .rc-tooltip-inner {
      padding: 0;
    }
  }
}

@media (max-width: 1100px) {
  .TooltipOverlay {
    &__action {
      .up {
        margin-left: 20px;
      }
    }
  }
}
