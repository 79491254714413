@import "../../../global-styles.scss";

.LetrusHighlighter__highlight {
  position: relative;
  background: rgba($primary-yellow, 0.9);
  box-shadow: 0 0 8px 3px rgba(black, 0.05);
  will-change: background color;

  * {
    position: relative;
    z-index: 1;
  }

  &:after {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 3px;
    box-shadow: 0 0 8px 3px rgba(black, 0.05);
    z-index: 0;
    opacity: 0;
    transition: all (2 * $animation-base-duration) $bounce-over-out-easing;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($primary-yellow, 0.9);
  }

  &:hover {
    background: lighten(rgba($primary-yellow, 0.9), 10%);
    color: rgba(black, 0.9);
    box-shadow: 0 0 8px 3px rgba(black, 0.05);
    transition: all 1s ease-in;
  }

  &:hover:after {
    animation-delay: 1s;
  }

  &:hover,
  &.LetrusHighlighter__highlight--visible {
    z-index: 5;
    position: relative;

    * {
      z-index: 3;
    }

    &:after {
      z-index: 2;
    }
  }
}

.LetrusHighlighter__highlight--mistake {
  background: none;
  border-bottom: solid 2px $error;
  box-shadow: none;

  &:hover {
    background: none;
    box-shadow: none;
    cursor: pointer;
  }
}
