@import "letrus-ui/dist/styles/_letrusTheme.scss";

.NewLogo {
  width: 100%;
  cursor: pointer;

  svg {
    height: 48px;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;

    .logo_letters_light {
      fill: $white !important;
    }

    .logo_letters_dark {
      fill: $black-80 !important;
    }
  }

  &.NewLogo--isLoading {
    svg {
      .logo_bars {
        animation: logo-bars-animation 1s ease-in-out infinite;
        will-change: transform opacity;

        > path:nth-child(1) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 1s;
        }

        > path:nth-child(2) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0.5s;
        }

        > path:nth-child(3) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0s;
        }
      }
    }
  }
  @keyframes logo-opacity-change {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  @keyframes logo-bars-animation {
    0% {
      transform: translateX(0%) scaleX(1);
    }

    50% {
      transform: scaleX(1.8) translateX(0);
    }

    90% {
      transform: translateX(120%) scaleX(1);
      opacity: 1;
    }

    91% {
      transform: translateX(-10%) scaleX(1);
      opacity: 0;
    }

    100% {
      transform: translateX(-10%) scaleX(1);
      opacity: 1;
    }
  }

  // Other loaders
  /*
  &.NewLogo--isLoading2 {
    svg {
      .logo_bars {
        will-change: transform opacity;

        > path:nth-child(1) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 1s;
        }

        > path:nth-child(2) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0.5s;
        }

        > path:nth-child(3) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0s;
        }
      }
    }
  }

  &.NewLogo--isLoading3 {
    svg {
      .logo_bars {
        animation: logo-bars-animation3 1s ease-in-out infinite alternate;
        will-change: transform opacity;
      }
    }
  }

  &.NewLogo--isLoading4 {
    svg {
      .logo_letters {
        display: none;
      }

      .logo_bars {
        will-change: transform opacity;
        transform: translateY(30%) scaleX(3) translateX(-15%);

        > path:nth-child(1) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 1s;
        }

        > path:nth-child(2) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0.5s;
        }

        > path:nth-child(3) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0s;
        }
      }
    }
  }


  @keyframes logo-bars-animation3 {
    0% {
      transform: translateX(0%) scaleX(1);
    }

    50% {
      transform: scaleX(1.8) translateX(0);
    }

    100% {
      transform: translateX(60%) scaleX(1);
    }
  }

  @keyframes logo-bars-animation4 {
    0% {
      transform: translateY(30%) translateX(0%) scaleX(1);
    }

    50% {
      transform: translateY(30%) scaleX(1.8) translateX(0);
    }

    100% {
      transform: translateY(30%) translateX(60%) scaleX(1);
    }
  }
  */
}
