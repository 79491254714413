@import "letrus-ui/dist/styles/_letrusTheme.scss";

// TODO - Replace color hexes with variables

.wrapper {
  background-color: #e6e6e6;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  background-color: $white;
  border-radius: 1rem;
  box-shadow: 0px 2px 4px 0px #1616161a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .image {
    height: 18rem;
    margin-bottom: 2rem;
  }

  h1 {
    @include heading4();
    margin-bottom: 1rem;
  }

  b {
    @include paragraph2();
    margin-bottom: 1rem;
  }

  p {
    @include paragraph6();
    margin-bottom: 2rem;
  }
}
