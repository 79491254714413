@import "letrus-ui/dist/styles/_letrusTheme.scss";
@import "../../../utils/styles/mixins";

.dropdownAccount {
  display: -webkit-box;

  .list {
    background-color: $teacher-sae-primary-hover;
    border-radius: 0.5rem;
    display: block;
    color: $white;
    margin-right: 1rem;
    margin-left: calculateRem(-100px);
    margin-top: calculateRem(45px);
    position: absolute;
    text-align: center;
    width: calculateRem(240px);
    z-index: 999;
    right: -3px;
    transition: display 0.4s ease;
    box-shadow: $drop-shadow-1;

    &:before {
      content: "";
      width: 30px;
      height: 22px;
      background: inherit;
      display: block;
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      z-index: -1;
      margin-top: -8px;
    }

    .avatar {
      border: 2px solid $white;
      border-radius: 50%;
      height: calculateRem(80px);
      margin: calculateRem(14px) auto;
      width: calculateRem(80px);
    }

    h3 {
      @include heading4;
    }

    ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: calculateRem(12px);
      padding: 0;
      list-style: none;

      .item {
        a {
          display: block;
          text-align: left;
          padding: 5px 25px;
          font-size: 16px;
          cursor: pointer;
          text-decoration: none;
          color: $white;

          svg {
            margin-right: 8px;
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
