@import "letrus-ui/dist/styles/_letrusTheme.scss";

* {
  font-family: "Anek Latin", sans-serif;
  margin: 0;
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  @include defaultScrollBarStyle();
}

html,
body {
  width: 100%;
  height: 100%;
}
